<template>
  <div class="productDetails">
    <div class="product-background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
          </div>
        </div>
      </div>

      <div class="container">
        <div class="product-info">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div class="cart-table table-responsive">
                <table class="table table-striped table-bordered" width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="product-remove">Order Number</th>
                      <th class="product-thumbnail">Products</th>
                      <th class="product-subtotal">Price (Tk)</th>
                      <th class="product-subtotal">Status</th>
                      <th class="product-subtotal">Order Date</th>
                      <th class="product-subtotal">Action</th>
                    </tr>
                  </thead>
                  <tr v-for="(order, index)  in orders.data" :key="index">
                    <td>
                       #{{ "" + order.order_no + order.id }}
                    </td>
                    <td>
                      <div v-for="(product, index)  in order.products" :key="index">
                          <div >
                              <p>{{ index+1 }} .
                                <router-link class="action" 
                                  :to="{name:'products-list',params:{id:product.id}}">
                                  {{ product.name }}
                                </router-link>
                              </p>
                              <p class="position">Quantity: <span class="color">{{ product.quantity }}</span> | Total Price: <span class="color">{{ product.total_price-product.product_discount }}</span></p>
                          </div> 
                        </div>
                      <h5></h5>
                    </td>
                    <td>
                      {{ parseInt(order.grand_total).toFixed(2) }} <br>(with 5% vat on total product price and 60tk Delivery Charges)
                    </td>
                    <td>
                      <span style="text-transform: capitalize">{{ order.status }}</span>
                    </td>
                    <td>
                      {{ order.order_date }}
                    </td>
                    <td style="text-align: center">
                      <button style="font-weight: bold;" @click="cancelOrder(order.id)" v-if="order.status == 'pending'" class="btn btn-info">Cancel</button>
                      <router-link class="action btn btn-success" 
                        :to="{name:'order.detail',params:{id:order.id}}">
                        Details 
                      </router-link>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <nav v-if="orders.last_page > 1" class="row flex justify-content-center">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: orders.current_page === 1 }">
          <button class="page-link" @click="changePage(1)">First</button>
        </li>
        <li class="page-item" :class="{ disabled: orders.current_page === 1 }">
          <button class="page-link" @click="changePage(orders.current_page - 1)">Previous</button>
        </li>

        <li class="page-item" v-for="page in orders.last_page" :key="page" :class="{ active: page === orders.current_page }">
          <button class="page-link" @click="changePage(page)">{{ page }}</button>
        </li>

        <li class="page-item" :class="{ disabled: orders.current_page === orders.last_page }">
          <button class="page-link" @click="changePage(orders.current_page + 1)">Next</button>
        </li>
        <li class="page-item" :class="{ disabled: orders.current_page === orders.last_page }">
          <button class="page-link" @click="changePage(orders.last_page)">Last</button>
        </li>
      </ul>
    </nav>
      </div>
      
    </div>
    <notifications group="cart" position="top left" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "Orders",
  props: ["title"],
  components: {  },
  data() {
    return {
      allProduct: [],
      img: "https://s3.ap-south-1.amazonaws.com/german-butcher/",
      quantity: 0,
      order_history: "",
      currentPage: 1
    };
  },

  computed: {
    ...mapGetters({
      orders: 'order/GET_ORDERS'
    }),
  },
  methods: {
    async cancelOrder(id){
        //this.loading = true
        let message = await this.$store.dispatch("order/ACT_CANCEL_ORDER", id)
        //console.log(message, "message")
        if(message.success == true){
            this.loading = false
            this.data = {}
        this.$notify({
            group: 'cart',
            type: 'success',
            title: 'Success',
            text: 'Order Canceled',
        });
        }else{
            this.loading = false
            this.$notify({
            group: 'cart',
            type: 'error',
            title: 'Error',
            text: message.message,
        });
        }
        
    },
    async changePage(page) {
    if (page <= this.orders.last_page) {
      await this.$store.dispatch('order/ACT_ORDER_LIST', page);
    }
    this.currentPage = page;
  },
    
  },
  mounted() {
    this.$store.dispatch('order/ACT_ORDER_LIST')
  }
};
</script>

<style scoped>
  /*@font-face {
    font-family: myThirdFont;
    src: url('/fonts/Ubuntu-M.ttf');
  }*/
    *{
      font-family: 'Ubuntu', sans-serif;
    }

    .breadcrumb-area:before {
    background-color: white !important;
    width: 0%;
    height: 0%;
}
    .btn {
      border-radius: 16px;
      font-size: 14px;
      padding: 0px 15px;
      height: 30px;
      line-height: 30px;
      margin-bottom: 0px !important; 
    }

    .fa-plus {
      transform: rotate(45deg);
    }
    .action {
      cursor: pointer;
    }
    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
      background-color: black;
      color: white;
    }
    .cart-product {
      margin-bottom: 122px;
    }
    .table td,
    .table th {
      padding: 0.75rem;
      vertical-align: middle;
      border-top: 1px solid #e9ecef;
      text-align: center;

    }

    .product-heading {
      text-align: center;
      color: white;
      margin-top: 45px;
    }
    .product-heading span {
      padding: 3px;
      text-align: center;
      font-weight: bold;
      color: #fff;
    }
    .product-info {
      background-color: white;
      margin-top: 45px;
      padding: 50px;
    }
    
    .action{
      font-weight: bold;
    }
    .action:hover{
      color: firebrick;
    }
    .position{
      margin-top: -15px;
      margin-left: 18px;
      font-size: 14px;
    }
    .color{
      color: firebrick;
    }
</style>
